import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  clearStateFn,
  resetBlockFn,
  initialStateObj,
} from "../../utils/common";
import { ApiService, url } from "../../utils/endpoints";
import { getSimplifiedError } from "../../utils/index";
import { toast } from "sonner";

const initialState = {
  ...initialStateObj,
  createMembershipState: { ...initialStateObj },
  deleteMemebershipState: { ...initialStateObj },
  editMembershipState: { ...initialStateObj },
};

const handleFulfilled = (
  state: any,
  initialStateKey: keyof typeof initialState | null,
  { payload }: { payload: any }
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = true;
    state.data = payload?.data;
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  }
};

export const startLoading = (
  state: typeof initialState,
  initialStateKey: keyof typeof initialState | null,
  payload: any
) => {
  if (initialStateKey === null) {
    state.loading = payload.loading;
  } else if (state.hasOwnProperty(initialStateKey)) {
    Object.keys(payload).forEach((key: string) => {
      if (state[initialStateKey].hasOwnProperty(key)) {
        /** @ts-ignore */
        state[initialStateKey][key] = payload[key];
      }
    });
  }
};

export const handleRejected = (
  state: any,
  initialStateKey: keyof typeof initialState | null,
  /** @ts-ignore */
  action: PayloadAction<any>
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = false;
    state.error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = false;
    state[initialStateKey].error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  }
};

export const postMembership = createAsyncThunk(
  "createMembership",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.post(url.membership, payload, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      toast.success(`${payload?.name} membership created successsfully`);
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const deleteMembership = createAsyncThunk(
  "deleteMembership",
  async (payload: string, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.delete(`${url.membership}/${payload}`, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      toast.success("Membership deleted successfully!");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const editMembership = createAsyncThunk(
  "editMembership",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const id = payload.id;
      delete payload.id;
      const user: any = getState();
      const { data } = await ApiService.put(`${url.membership}/${id}`, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      toast.success("Membership edited successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    clearState: (state) => {
      /** @ts-ignore */
      return clearStateFn(state, initialState);
    },
    resetMembershipBlock: (state, action) => {
      return resetBlockFn(state, action, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMembership.pending, (state, action) => {
        startLoading(state, "createMembershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(postMembership.fulfilled, (state, action) => {
        handleFulfilled(state, "createMembershipState", action);
      })
      .addCase(postMembership.rejected, (state, action) => {
        handleRejected(state, "createMembershipState", action);
      })
      .addCase(deleteMembership.pending, (state, action) => {
        startLoading(state, "deleteMemebershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(deleteMembership.fulfilled, (state, action) => {
        handleFulfilled(state, "deleteMemebershipState", action);
      })
      .addCase(editMembership?.pending, (state, action) => {
        startLoading(state, "editMembershipState", {
          loading: true,
          success: false,
        });
      })
      .addCase(editMembership?.fulfilled, (state, action) => {
        handleFulfilled(state, "editMembershipState", action);
      })
      .addCase(editMembership.rejected, (state, action) => {
        handleRejected(state, "editMembershipState", action);
      });
  },
});

export const membershipSelector = (state: any) => state.membership;
export const { clearState, resetMembershipBlock } = membershipSlice.actions;

export default membershipSlice.reducer;
