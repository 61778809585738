import React, { useContext } from "react";
import PageLoader from "../../components/Loader/PageLoader";
import { IInternalStructure, IWidgetData } from "../../types";
import { structure } from "../../utils/mockStructure";
import IntroWidget from "./components/Widget/IntroWidget";
import SocialWidget from "./components/Widget/Socials";
import SupportWidget from "./components/Widget/SupportWidget";
import WishList from "./components/Widget/WIshList";
import { PageContext } from ".";
import AboutHeader from "./components/AboutHeader";
import { MetaTagsConfig } from "../../utils/types";
import { MetaTags } from "../../components/MetaTags";

const internalStructure: IInternalStructure = {
  intro: <IntroWidget data={{}} />,
  support: <SupportWidget data={{}} />,
  wishlist: <WishList data={{}} />,
};

interface ComponentRendererProps {
  component: React.ReactElement<{ data: IWidgetData }>;
  data: IWidgetData;
}

const ComponentRenderer: React.FC<ComponentRendererProps> = ({
  component,
  data,
}) => {
  return React.cloneElement(component, { data });
};

const CreatorsPage = () => {
  const { data, loading } = useContext(PageContext);

  const metaConfig: MetaTagsConfig = {
    title: `Buymejollof - Support and Buy ${data?.creator?.username} a plate of ${data?.jollofPage?.plateName}`,
    description: `Buymejollof - Give ${data?.creator?.username} a one-time or monthly support, shop and create moment with ${data?.creator?.username}`,
    url: `https://www.buymejollof.com/${data?.creator?.username}`,
    image:
      "https://res.cloudinary.com/groomlyhq/image/upload/q_60/v1693640963/marketing-website/happy_superfans-min_txjaax?_a=BAJFJtWI0",
    username: data?.creator?.username,
  };

  return (
    <>
      <MetaTags config={metaConfig} />
      <section className="w-full">
        <AboutHeader data={data?.creator} />
        {loading && (
          <section className="py-20">
            <PageLoader
              color="#121212"
              title="Setting up your creator's page"
              body="We are cooking you the best jollof, please wait..."
            />
          </section>
        )}
        {!loading && data && (
          <section className="mt-6 flex flex-col md:flex-row gap-3">
            <section>
              {structure?.widgets.intro && (
                <ComponentRenderer
                  component={internalStructure?.intro}
                  data={data as IWidgetData}
                />
              )}
            </section>
            <section className="flex flex-col gap-3">
              <section className="w-full">
                {structure?.widgets.support && (
                  <ComponentRenderer
                    component={internalStructure?.support}
                    data={
                      {
                        ...data,
                        name: data?.creator?.name,
                        username: data?.creator?.username,
                      } as IWidgetData
                    }
                  />
                )}
              </section>
              <section className="flex gap-3">
                <section className="flex-1">
                  {structure?.widgets?.wishlist && (
                    <ComponentRenderer
                      component={internalStructure?.wishlist}
                      data={data as IWidgetData}
                    />
                  )}
                </section>
                <section className="flex-1 hidden md:flex">
                  <SocialWidget data={data} />
                </section>
              </section>
            </section>
          </section>
        )}
      </section>
    </>
  );
};

export default CreatorsPage;
