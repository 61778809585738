import AppButton from "../AppButton";

const EmptyState = ({
  body,
  imgSrc,
  onClick,
  secondaryButton,
  secondaryButtonClick,
  secondaryButtonTitle,
  title,
}: {
  body: string;
  imgSrc: any;
  onClick: any;
  title?: string;
  secondaryButtonClick?: any;
  secondaryButtonTitle?: string;
  secondaryButton?: boolean;
}) => {
  return (
    <section className="sm:max-w-sm mx-auto flex flex-col justify-center items-center">
      {imgSrc}
      <p className="pt-8 font-gt-w-regular text-base text-center text-neutral-900">
        {body}
      </p>

      <div className="pt-10 flex gap-3">
        {secondaryButton && (
          <AppButton
            onClick={secondaryButtonClick}
            title={secondaryButtonTitle}
            intent="secondary"
            size={"medium"}
          />
        )}
        {title ? (
          <AppButton
            onClick={onClick}
            title={title}
            intent={"primary"}
            size={"medium"}
          />
        ) : null}
      </div>
    </section>
  );
};

export default EmptyState;
