import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSimplifiedError } from "../../utils/index";
import { ApiService, url } from "../../utils/endpoints";
import { toast } from "sonner";

const block = {
  loading: false,
  success: false,
  error: { status: null, message: "" },
  data: {},
};

const initialState = {
  ...block,
  editCreatorsPageState: { ...block },
};

const handleFulfilled = (
  state: any,
  initialStateKey: keyof typeof initialState | null,
  { payload }: { payload: any }
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = true;
    state.data = payload?.data;
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = true;
    state[initialStateKey].data = {
      ...payload.data,
    };
  }
};

export const startLoading = (
  state: typeof initialState,
  initialStateKey: keyof typeof initialState | null,
  payload: any
) => {
  if (initialStateKey === null) {
    state.loading = payload.loading;
  } else if (state.hasOwnProperty(initialStateKey)) {
    Object.keys(payload).forEach((key: string) => {
      if (state[initialStateKey].hasOwnProperty(key)) {
        /** @ts-ignore */
        state[initialStateKey][key] = payload[key];
      }
    });
  }
};

export const handleRejected = (
  state: any,
  initialStateKey: keyof typeof initialState | null,
  action: PayloadAction<any>
) => {
  if (initialStateKey === null) {
    state.loading = false;
    state.success = false;
    state.error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  } else if (state.hasOwnProperty(initialStateKey)) {
    state[initialStateKey].loading = false;
    state[initialStateKey].success = false;
    state[initialStateKey].error = {
      status: action.payload?.status || null,
      message: action.payload?.message || "",
    };
  }
};

export const getCreatorsPage = createAsyncThunk(
  "creatorspage",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.get(
        `${url.creatorspage}/${payload}/page`,
        {
          headers: { Authorization: `Bearer ${user?.user?.token}` },
        }
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const updateCreatorsPage = createAsyncThunk(
  "updateCreatorsPage",
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const user: any = getState();
      const { data } = await ApiService.put(url.creatorspage, payload, {
        headers: { Authorization: `Bearer ${user?.user?.token}` },
      });
      toast.success("Your page settings updated successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue(getSimplifiedError(error));
    }
  }
);

export const creatorPageSlice = createSlice({
  name: "creatorsPage",
  initialState,
  reducers: {
    clearState: (state) => {
      Object.assign(state, initialState);
    },
    resetBlockAuth(
      state,
      action: PayloadAction<{ blockType: keyof typeof initialState | null }>
    ) {
      return action.payload.blockType === null
        ? { ...state, loading: false, success: false }
        : {
            ...state,
            [action.payload.blockType]: {
              /** @ts-ignore */
              ...initialState[action.payload.blockType],
            },
          };
    },
    resetFlag(state, action: PayloadAction<{ blockType: string }>) {
      const { blockType } = action.payload;
      /** @ts-ignore */
      const initialStateForKey = initialState[blockType];
      if (blockType === null) {
        return {
          ...initialState,
          data: state.data, // Retain data
        };
      }
      return {
        ...state,
        [blockType]: {
          /** @ts-ignore */
          ...state[blockType],
          loading: initialStateForKey.loading,
          success: initialStateForKey.success,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreatorsPage.pending, (state, action) => {
        startLoading(state, null, { loading: true, success: false });
      })
      .addCase(getCreatorsPage.fulfilled, (state, action) => {
        handleFulfilled(state, null, action);
      })
      .addCase(getCreatorsPage.rejected, (state, action) => {
        handleRejected(state, null, action);
      })
      .addCase(updateCreatorsPage.pending, (state, action) => {
        startLoading(state, "editCreatorsPageState", {
          loading: true,
          success: false,
        });
      })
      .addCase(updateCreatorsPage.fulfilled, (state, action) => {
        handleFulfilled(state, "editCreatorsPageState", action);
      })
      .addCase(updateCreatorsPage.rejected, (state, action) => {
        handleRejected(state, "editCreatorsPageState", action);
      });
  },
});

export const creatorsPageSelector = (state: any) => state.creatorsPage;
export const { clearState, resetBlockAuth, resetFlag } =
  creatorPageSlice.actions;

export default creatorPageSlice.reducer;
