import { CloudinaryImage } from "@cloudinary/url-gen";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { toast } from "sonner";

const AssetTransform = (imgName: string) => {
  return new CloudinaryImage(imgName, { cloudName: "groomlyhq" }).delivery(
    quality(60)
  );
};

export default AssetTransform;

export const getSimplifiedError = (error: any) => {
  console.log(error.response);
  if (!error.response) {
    toast.error(
      "Something went wrong, check your internet and please try again"
    );
    return "Something went wrong, check your internet and please try again";
  }
  if (error.response?.status === 500) {
    toast.error("Sorry an unexpected error occurred.");
    return "Sorry an unexpected error occurred.";
  }
  if (error.response?.status === 503) {
    if (
      error.response?.data?.message ===
      "Error occurred while communicating with email service."
    ) {
      toast.error(
        "We couldn't connect to our email server, if it persist, please use our help desk at the bottom right we response in 5 minutes"
      );
      return "Sorry an unexpected error occurred.";
    } else {
      toast.error(
        "Sorry an unexpected error occurred, if it persist, please use our help desk at the bottom right we response in 5 minutes"
      );
      return "Sorry an unexpected error occurred.";
    }
  }

  if (error.response.status === 404) {
    if (
      error.response.data.error === "user not found" ||
      error.response?.data?.message === "user not found"
    ) {
      toast.error(error.response.data.message);
      setTimeout(() => {
        window.location.replace("/register");
      }, 1000);
    }

    if (error.response?.data?.message === "Record not found") {
      return toast.error("You need to edit your public page and publish it");
    }
    return error.response.data.error;
  } else if (error.response.status === 400) {
    toast.error(error.response.data.message);
    return "Token has expired, please log in";
  } else if (error.response.status === 401) {
    if (error.response.data.message === "Invalid credentials") {
      toast.error("Your email or password is incorrect");
    }
    toast.error(error.response.data.message || error.response.data.error);
    return error.response.data.message;
  } else {
    toast.error(error.response.data.error);
    return error.response.data.error;
  }
};
