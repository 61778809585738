import clsx from "clsx";
import { ShareIcon, Copy, Twitter, Facebook } from "../../../../assets/icons";
import { useState } from "react";
import { toast } from "sonner";

const SharePage = ({
  username,
  plateName,
}: {
  username: string;
  plateName: string;
}) => {
  const [showShare, setShowShare] = useState(false);

  const copyToClipboard = (url: string) => {
    navigator?.clipboard?.writeText(url);
    setTimeout(() => {
      setShowShare(false);
    }, 2000);
    toast.success("Public profile page link copied");
  };

  const handleOpenShareMenu = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setShowShare(!showShare);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="bg-[#f5f5f5] p-2 rounded-lg flex flex-col relative">
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={(event) => handleOpenShareMenu(event)}
        >
          <ShareIcon width="20px" height="20px" />
          <span className="text-sm font-gt-w-medium">Share page</span>
        </div>
        <div
          className={clsx(
            "absolute top-10 w-52 pl-4 py-4 gap-6 flex-col right-0 shadow-date bg-white rounded-lg",
            showShare ? "flex" : "hidden"
          )}
        >
          <span
            className="text-base cursor-pointer font-gt-w-regular flex gap-2 items-center"
            onClick={() =>
              copyToClipboard(`https://www.buymejollof.com/${username}`)
            }
          >
            <Copy /> Copy public profile
          </span>
          <a
            href={`https://twitter.com/intent/tweet?text=I'm on @buymejollof. If you like my work, enjoy my content, you can buy me a plate of ${plateName} and share your thoughts 🎉 https://www.buymejollof.com/${username}`}
            className="w-full"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-base font-gt-w-regular flex gap-2 items-center">
              <Twitter width="20px" height="20px" fill="black" />
              Tweet
            </span>
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.buymejollof.com/${username}`}
            className="w-full flex"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-base font-gt-w-regular flex gap-2 items-center">
              <Facebook width="20px" height="20px" />
              Share on fb
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SharePage;
