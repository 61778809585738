import React from "react";
import { Helmet } from "react-helmet-async";
import { MetaTagsConfig } from "../../utils/types";

interface MetaTagsProps {
  config: MetaTagsConfig;
}

export const MetaTags: React.FC<MetaTagsProps> = ({ config }) => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{config.title}</title>
      <meta name="description" content={config.description} />
      <link rel="canonical" href={config.url} />

      {/* OpenGraph Meta Tags */}
      <meta property="og:url" content={config.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={config.title} />
      <meta property="og:description" content={config.description} />
      <meta property="og:image" content={config.image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="Buymejollof" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="buymejollof.com" />
      <meta property="twitter:url" content={config.url} />
      <meta name="twitter:title" content={config.title} />
      <meta name="twitter:description" content={config.description} />
      <meta name="twitter:image" content={config.image} />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content={config.username || "Buymejollof"} />
      <meta
        property="article:author"
        content={config.username || "Buymejollof"}
      />
      <meta
        property="article:modified_time"
        content={new Date().toISOString()}
      />
    </Helmet>
  );
};
