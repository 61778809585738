import { Link, useLocation, useNavigate } from "react-router-dom";
import MembershipCard from "./components/MembershipCard";
import EmptyState from "../../components/EmptyState";
import { WishlistEmptyImage } from "../../assets/images";
import {
  ChangeEvent,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  useContext,
  useEffect,
  useState,
} from "react";
import { PageContext } from ".";
import Modal from "../../components/Modal/index";
import { IMembershipData } from "../../types";
import AppInput from "../../components/AppInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { oneTimeSupportSchema } from "../../utils/validation.schema";
import TextArea from "../../components/TextArea";
import CurrencyFormat from "react-currency-format";
import { getCurrency } from "../../utils/index";
import { CheckCircle } from "../../assets/icons";
import AppButton from "../../components/AppButton";
import Loader from "../../components/Loader";
import { ApiService } from "../../utils/endpoints";
import { Copy, Facebook, Telegram, Twitter, Whatsapp } from "../../assets";
import { getSimplifiedError } from "../../utils";

const url = "https://api.fitness.realjay.dev";

const ListMemberships = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let prevLink = location.pathname.split("/")[1];

  const [isVisible, setIsvisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifiedData, setVerifiedData] = useState(false);
  const [referenceId, setReference] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    message: "",
    email: "",
  });

  const { data } = useContext(PageContext);
  const plans = data?.jollofPage?.plans;

  const [copied, setCopied] = useState(false);
  const urlWebsite = window.location.href;

  function removeQueryParams(url: string) {
    const urlObject = new URL(url);
    return `${urlObject.origin}${urlObject.pathname}`;
  }

  const cleanUrl = removeQueryParams(urlWebsite);

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(oneTimeSupportSchema),
  });

  const onNavigateBack = () => {
    navigate(`/${prevLink}`);
  };

  const onShowSubModal = (data: IMembershipData) => {
    setIsvisible(!isVisible);
    setSelectedPlan(data);
  };

  const onHandleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const onSubmit = () => {
    const payload = {
      name: userData?.name,
      email: userData?.email,
      message: userData?.message,
      planId: selectedPlan?.id,
      callbackUrl: window.location.href,
    };
    setLoading(true);
    let username = data?.creator?.username;
    ApiService.post(`${url}/supporter/jollof/${username}/subscribe`, payload)
      .then(({ data }) => {
        window.location.replace(data?.data?.gatewayData?.authorization_url);
        setLoading(false);
        setVerifying(true);
      })
      .catch((error) => {
        setLoading(false);
        getSimplifiedError(error);
      });
  };

  // Function to parse query parameters
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const queryParams = getQueryParams(location.search);

  // Accessing individual query parameters
  const trxref = queryParams.get("trxref");
  const reference = queryParams.get("reference");

  useEffect(() => {
    if (reference) {
      setReference(reference);
      setVerifying(true);
      setIsvisible(true);
      onVerifyPayment();
    }
  }, [reference]);

  const onVerifyPayment = () => {
    ApiService.get(`${url}/payment/verify/${reference}`)
      .then(({ data }) => {
        setVerifying(false);
        setIsVerified(true);
        setIsvisible(true);
        setVerifiedData(data);
      })
      .catch((error) => {
        setVerifying(false);
        getSimplifiedError(error);
      });
  };

  const clearQueryParams = () => {
    const path = location.pathname;
    navigate(path, { replace: true });
    setIsvisible(false);
    setVerifying(false);
    setIsVerified(false);
  };

  return (
    <section className="w-full">
      <div className="flex flex-col">
        <span
          onClick={onNavigateBack}
          className="cursor-pointer flex gap-1 rounded-full max-w-fit text-xs  items-center md:text-base text-neutral-900 font-gt-w-medium mb-4"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 12H4M4 12L10 6M4 12L10 18"
              stroke="#1C274C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Go back
        </span>
        <h1 className="text-xl md:text-2xl font-gt-su-md">
          Join my membership
        </h1>
      </div>
      {!plans?.length ? (
        <section className="w-full pt-3 md:pt-5">
          <section className="max-w-96 bg-white rounded-2xl p-4 md:p-8 d-flex flex-col justify-center items-center">
            <EmptyState
              imgSrc={<WishlistEmptyImage />}
              onClick={() => navigate(`${location.pathname}/create`)}
              body="No monthly membership plans have been created yet!"
            />
          </section>
        </section>
      ) : (
        <section className="pt-3 md:pt-5 grid grid-cols-1 md:grid-cols-2 gap-[18px]">
          {plans?.length
            ? plans?.map((datum: any) => {
                return (
                  <MembershipCard
                    data={datum}
                    key={datum?.name}
                    onClick={onShowSubModal}
                  />
                );
              })
            : null}
        </section>
      )}

      {isVisible ? (
        <Modal onClose={() => clearQueryParams()}>
          <div className="p-6">
            {!verifying && !isVerified ? (
              <div className="form">
                <h1 className="text-lg font-gt-su-bd text-center">
                  Become a member for{" "}
                  <CurrencyFormat
                    value={selectedPlan?.amount} //come back to convert to kobo/cent
                    prefix={getCurrency(selectedPlan?.currency)}
                    thousandSeparator={true}
                    displayType="text"
                  />{" "}
                  / month
                </h1>
                <p className="text-sm font-gt-w-regular text-center">
                  By becoming a member of {selectedPlan?.name}, you get to
                  support me monthly, and I make sure to provide rewards for
                  this.{" "}
                </p>

                <div className="flex flex-col mt-10">
                  <h6 className="text-sm font-gt-w-medium text-neutral-700 uppercase">
                    {selectedPlan?.name}
                  </h6>
                  <p className="text-sm font-gt-w-regular py-2">
                    {selectedPlan?.description}
                  </p>
                  <div className="mt-4">
                    <h6 className="text-sm font-gt-w-medium text-neutral-700 uppercase">
                      Rewards
                    </h6>
                    <ul className="mt-2">
                      {selectedPlan?.rewards?.length ? (
                        selectedPlan?.rewards.map(
                          (
                            reward: {
                              title:
                                | string
                                | number
                                | boolean
                                | ReactElement<
                                    any,
                                    string | JSXElementConstructor<any>
                                  >
                                | Iterable<ReactNode>
                                | ReactPortal
                                | null
                                | undefined;
                            },
                            i: Key | null | undefined
                          ) => {
                            return (
                              <li
                                key={i}
                                className="flex items-center gap-2 text-sm font-gt-w-regular text-neutral-700 mb-[14px]"
                              >
                                <span>
                                  <CheckCircle />
                                </span>
                                {reward.title}
                              </li>
                            );
                          }
                        )
                      ) : (
                        <li className="flex items-center gap-2 text-sm font-gt-w-regular text-neutral-700 mb-[14px]">
                          <span>
                            <CheckCircle />
                          </span>
                          You just want to support me on a monthly basis.
                          Awwnnn, thank you
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-col mt-5">
                  <div className="flex-1">
                    <AppInput
                      label="Full name"
                      placeholder="Please enter your full name"
                      name="name"
                      type="text"
                      height={"42px"}
                      register={register}
                      errors={errors}
                      onChange={onHandleChange}
                      noLabel={true}
                    />
                  </div>
                  <div className="mt-2 flex-1">
                    <AppInput
                      label="Email address"
                      placeholder="johndoe@gmail.com"
                      name="email"
                      type="text"
                      height={"42px"}
                      register={register}
                      errors={errors}
                      onChange={onHandleChange}
                      noLabel={true}
                    />
                  </div>
                  <div className="mt-2">
                    <TextArea
                      label="your name"
                      placeholder={`Send nice message to ${data?.creator?.name}`}
                      name="message"
                      register={register}
                      errors={errors}
                      noLabel={true}
                      onChange={onHandleChange}
                      height="h-20"
                    />
                  </div>
                </div>
                <section className="my-4">
                  <AppButton
                    intent="primary"
                    size={"medium"}
                    fullWidth={true}
                    title={`Join now`}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={loading}
                  />
                </section>
                <span className="text-xs font-gt-w-regular text-neutral-600">
                  Payment secured by Paystack. You’ll be taken to a thank you
                  page after the payment.{" "}
                  <Link to="/terms" className="underline">
                    Terms
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy" className="underline">
                    Privacy
                  </Link>
                  .
                </span>
              </div>
            ) : null}

            {verifying && !isVerified ? (
              <div className="mt-3 text-center sm:mt-5">
                <Loader color="orange" />
                <h3 className="text-base font-gt-w-medium leading-6 text-gray-900">
                  Verifying your payment
                </h3>
                <div className="mt-2">
                  <p className="text-sm font-gt-w-regular text-neutral-700">
                    Please wait, we are trying to verify your monthly support
                    payment to {data?.creator?.username} {selectedPlan?.name}{" "}
                    membership
                  </p>
                </div>
              </div>
            ) : null}

            {!verifying && isVerified ? (
              <div className="flex w-full flex-col justify-center items-center">
                <span className="w-16 h-16 flex items-center justify-center bg-[#EAF6E9] rounded-full">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM32.0607 17.9393C32.6464 18.5251 32.6464 19.4749 32.0607 20.0607L22.0607 30.0607C21.4749 30.6464 20.5251 30.6464 19.9393 30.0607L15.9393 26.0607C15.3536 25.4749 15.3536 24.5251 15.9393 23.9393C16.5251 23.3536 17.4749 23.3536 18.0607 23.9393L21 26.8787L25.4697 22.409L29.9393 17.9393C30.5251 17.3536 31.4749 17.3536 32.0607 17.9393Z"
                      fill="#30A727"
                    />
                  </svg>
                </span>
                <h3 className="text-base font-gt-w-medium leading-6 text-gray-900">
                  Support successful
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-center font-gt-w-regular text-neutral-700">
                    You rock my g, your monthly support payment has been
                    confirmed to be sent to {data?.creator?.username}
                  </p>
                </div>
                <span className="w-full flex justify-items-start text-sm font-gt-w-regular mt-10">
                  A message from {data?.creator?.username}
                </span>
                <div className="bg-[#F4F5F5] p-3 w-full rounded-sm mt-2">
                  <p className="text-sm font-gt-w-regular text-[#626262]">
                    {selectedPlan?.welcomeNote}
                  </p>
                </div>
                <div className="w-full mt-5 text-center">
                  <h6 className="font-gt-w-medium text-base">
                    Spread the word
                  </h6>
                  <p className="font-gt-w-regular text-sm">
                    {data?.creator?.username} would love a shout on your socials
                  </p>
                  <div className="w-full mt-2 bg-[#f6f6f6] px-3 py-2 flex items-center justify-between rounded-[12px]">
                    <p className="font-regular text-[#777] font-gt-w-medium text-xs sm:text-sm md:text-base">
                      {cleanUrl}
                    </p>
                    <button
                      onClick={() => copyToClipboard(cleanUrl)}
                      className="cursor-pointer"
                    >
                      <Copy />
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mt-2">
                    <a
                      href={`https://twitter.com/intent/tweet?url=${cleanUrl}`}
                      className="w-full flex flex-col items-center"
                    >
                      <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto">
                        <Twitter width="24px" height="24px" />
                      </div>
                    </a>

                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Facebook width="24px" height="24px" />
                      </a>
                    </div>
                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://telegram.me/share/url?url=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Telegram width="24px" height="24px" />
                      </a>
                    </div>

                    <div className="w-full flex flex-col items-center">
                      <a
                        href={`https://telegram.me/share/url?url=${cleanUrl}`}
                        className="w-10 h-10 rounded-full flex justify-center items-center bg-[#f6f6f6] cursor-pointer outline-none border-none mx-auto"
                      >
                        <Whatsapp width="24px" height="24px" />
                      </a>
                    </div>
                    {/* end of a session */}
                  </div>
                  {copied && (
                    <p className="text-center text-sm font-gt-w-regular text-green-500 mt-4">
                      Copied to clipboard!
                    </p>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </section>
  );
};

export default ListMemberships;
