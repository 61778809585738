import AppButton from "../../../../components/AppButton";
import UserInfo from "../../../../components/UserInfo";
import { useWindowSize } from "@uidotdev/usehooks";

const AboutHeader = ({ data }: any) => {
  const size = useWindowSize().width ?? 0;
  return (
    <section className="w-full flex flex-col md:flex-row justify-between md:items-center mt-5 md:mt-0">
      <UserInfo
        size="large"
        withImage={true}
        title={data?.name}
        userName={data?.username}
        subTitle={data?.about}
        hasWidth={true}
        url={""}
      />
      {/* {size <= 540 ? null : (
        <AppButton
          title="Publish your page"
          size={"medium"}
          isLoading={false}
        />
      )} */}
    </section>
  );
};

export default AboutHeader;
